import {baseApi} from "state/slices/apiSlice";
import {ApiPaths} from "navigation/routes/ROUTE_PATHS";

const serializeUpdatePayload = (payload) => {
  var body = {};
  if ("status" in payload) {
    body = {
      ...body,
      status: payload.status,
      selected_prescription_configuration_id: payload.selected_prescription_configuration_id,
    };
  }
  return body;
};

export const projectApi = baseApi.injectEndpoints({
  tagTypes: ["Projects", "Buildings"],
  endpoints: (build) => ({
    getProject: build.query({
      query: ({id}) => ApiPaths.PROJECT + id,
      providesTags: ["Project"],
    }),
    updateProject: build.mutation({
      query(payload) {
        return {
          url: ApiPaths.PROJECT + payload.id,
          method: "PUT",
          body: serializeUpdatePayload(payload),
        };
      },
      invalidatesTags: ["Projects", "Buildings"],
    }),
    getProjectOverview: build.query({
      query: ({id}) => ApiPaths.PROJECT_OVERVIEW + id,
      providesTags: ["Project"],
    }),
  }),
});

export const {useGetProjectQuery, useUpdateProjectMutation, useGetProjectOverviewQuery} = projectApi;
