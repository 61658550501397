import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const weatherBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_WEATHERBIT_API_ENDPOINT,
});

export const weatherBaseApi = createApi({
    reducerPath: "weatherBaseApi",
    baseQuery: weatherBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["Weather"],
});
