import {
    SELECTED_LANG_KEY,
    SUPPORTED_LANG_LIST
} from '../../constants';

import moment from "moment";
import 'moment/locale/fr';

// -- LANGUAGE MANAGEMENT --
const languageKeyIsValid = (languageKey) => {
    return SUPPORTED_LANG_LIST.filter(lang=>lang.key === languageKey).length > 0
}

const getLanguageInfoFromKey = (languageKey) => {
    const key = languageKeyIsValid(languageKey) ? languageKey: "en";
    return SUPPORTED_LANG_LIST.filter(lang=>lang.key === key)[0]
}

export const manageLanguages = (i18n_instance) => {
    const setLanguage = (languageKey) => {
        if (languageKeyIsValid(languageKey)) {
            i18n_instance.changeLanguage(languageKey);
            moment.locale(languageKey);
            localStorage.setItem(SELECTED_LANG_KEY, languageKey);
        }
    }
    return {
        setLanguage
    }
}

export const getCurrentLanguageInfo = () => {
    const currentLanguageKey = localStorage.getItem(SELECTED_LANG_KEY) ? localStorage.getItem(SELECTED_LANG_KEY) : "en";
    const currentLanguage = getLanguageInfoFromKey(currentLanguageKey);
    return currentLanguage;
}

export {SUPPORTED_LANG_LIST as languageList}
