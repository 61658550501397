// import 'react-router-dom'
import React from "react";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import RoutePaths from "./ROUTE_PATHS";

import { AuthLayout, DashboardLayout } from "layouts";

import { mainLayoutRoutes, authLayoutRoutes, mapLayoutRoutes } from "./index";

const childRoutes = (Layout, routes, layoutProps) =>
    routes.map(({ component: Component, guard, children, path, ...props }, index) => {
        const Guard = guard || React.Fragment;

        return children ? (
            children.map((element, index) => {
                const Guard = element.guard || React.Fragment;
                const ElementComponent = element.component || React.Fragment;

                return (
                    <Route
                        key={index}
                        path={element.path}
                        element={
                            <Layout {...layoutProps}>
                                <Guard>
                                    <ElementComponent {...props} />
                                </Guard>
                            </Layout>
                        }
                    />
                );
            })
        ) : Component ? (
            <Route
                key={index}
                path={path}
                element={
                    <Layout {...layoutProps}>
                        <Guard>
                            <Component {...props} />
                        </Guard>
                    </Layout>
                }
            />
        ) : null;
    });

const Routes = () => {
    return (
        <ReactRoutes>
            <Route path="/" element={<Navigate to={RoutePaths.CUSTOMER_HOME} />} />
            {/* {childRoutes(OnboardingLayout, onboardingLayoutRoutes)} */}
            {childRoutes(DashboardLayout, mainLayoutRoutes)}
            {childRoutes(DashboardLayout, mapLayoutRoutes, { mainContentPadding: 0 })}
            {childRoutes(AuthLayout, authLayoutRoutes)}
        </ReactRoutes>
    );
};

export default Routes;
