import React from "react";
import styled, {useTheme} from "styled-components";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import {useTranslation} from "react-i18next";

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
`;

const ListItem = styled(MuiListItem)`
    display: inline-block;
    width: auto;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
    color: ${(props) => props.theme.footer.color};

    &,
    &:hover
`;

const ListItemText = styled(MuiListItemText)`
    span {
        color: ${(props) => props.theme.footer.color};
    }
`;

function Footer() {
  const theme = useTheme();
  const {t, i18n} = useTranslation();

  return (
    <AppBar position="sticky" elevation={0} sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
      <Toolbar>
        <Hidden mdDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                button={true}
                component="a"
                href={i18n.language === "fr" ? process.env.REACT_APP_PRIVACY_POLICY_FR_URL : process.env.REACT_APP_PRIVACY_POLICY_EN_URL}
                target="_blank"
              >
                <ListItemText primary={t("privacy_policy")} />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItem button={false}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography mr={2}>{`© ${new Date().getFullYear()}`}</Typography>
                <img src={theme.footer.logo} alt={"vadiMAP logo"} width={120} />
              </Box>
            </ListItem>
          </List>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
