import React, {createContext, useEffect, useMemo, useState} from "react";
import Routes from "./navigation/routes";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {ThemeProvider} from "styled-components";
import {ThemeProvider as MuiThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import createTheme from "./theme";
import ActionIconButtons from "components/ActionIconButtons";
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {BrowserRouter as Router} from "react-router-dom";
import {useGetAccountQuery} from "api/accountApi";
import {SelectedPrescriptionConfigurationContext} from "../src/pages/Prescription/contexts/SelectedPrescriptionConfiguration";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export const AccountContext = createContext();

export const ThemeModeContext = createContext();

function App() {
  const [mode, setMode] = useState("DARK");

  const [account, setAccount] = useState({});

  const theme = useMemo(() => createTheme(mode), [mode]);

  const {data, isFetching, isSuccess} = useGetAccountQuery();

  useEffect(() => {
    if (isSuccess) {
      setAccount(data);
    }
  }, [isFetching]);

  return (
    <>
      <CacheProvider value={muiCache}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <AccountContext.Provider value={{account, setAccount}}>
                  <ThemeModeContext.Provider value={{mode, setMode}}>
                    <SelectedPrescriptionConfigurationContext>
                      <Router>
                        <Routes />
                      </Router>
                    </SelectedPrescriptionConfigurationContext>
                    <ActionIconButtons/>
                  </ThemeModeContext.Provider>
                </AccountContext.Provider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </CacheProvider>
    </>
  );
}

export default App;
