import { baseApi } from "state/slices/apiSlice";
import { ApiPaths } from "navigation/routes/ROUTE_PATHS";

const accountApi = baseApi.injectEndpoints({
    tagTypes: ["Account"],
    endpoints: (build) => ({
        getAccount: build.query({
            query: () => ApiPaths.ACCOUNT_INFO,
            providesTags: ["Account"],
        }),
        createUpdateAccount: build.mutation({
            query(payload) {
                return {
                    url: ApiPaths.ACCOUNT_INFO,
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["Account", "Buildings", "Projects"],
        }),
        updateObjectiveWeights: build.mutation({
            query(payload) {
                return {
                    url: ApiPaths.OBJECTIVE_WEIGHTS,
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["Account"],
        }),
    }),
});

export const { useGetAccountQuery, useCreateUpdateAccountMutation, useUpdateObjectiveWeightsMutation } = accountApi;
