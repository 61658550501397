import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import Header from "./components/AppBar";
import Footer from "./components/Footer";
import {Outlet} from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

import {Box, useMediaQuery, useTheme} from "@mui/material";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

const drawerWidth = 258;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up("md")} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
`;

const MainContent = styled(Box)`
    flex: 1;
    background: ${(props) => props.theme.palette.background.default};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }
`;

const Dashboard = ({children, routes, mainContentPadding}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [innerMainContentPadding, setInnerMainContentPadding] = useState(mainContentPadding);

  useEffect(() => {
    setInnerMainContentPadding(mainContentPadding);
  }, [mainContentPadding]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isLgUp = useIsWidthUp("lg");
  const isMdUp = useIsWidthUp("md");

  return (
    <>
      <ToastContainer theme="colored"/>
      <Box display={"flex"} minHeight={"100vh"}>
        <CssBaseline/>
        <Drawer>
          <Sidebar
            sx={{display: {md: "none", xs: "block"}}}
            routes={routes}
            PaperProps={{style: {width: drawerWidth}}}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
          <Sidebar
            sx={{display: {xs: "none", md: "block"}}}
            routes={routes}
            PaperProps={{style: {width: drawerWidth}}}
          />
        </Drawer>
        <AppContent>
          <Header onDrawerToggle={handleDrawerToggle} showLogo={!isMdUp}/>
          <MainContent p={innerMainContentPadding === undefined ? (isLgUp ? 12 : 5) : innerMainContentPadding}>
            {children}
          </MainContent>
          <Footer/>
        </AppContent>
        <Outlet/>
      </Box>
    </>
  );
};

export default Dashboard;
