import React, {useEffect, useState} from "react";
import {Box, IconButton, useTheme} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import {useTranslation} from "react-i18next";
import SupportRequestDialog from "./SupportRequestDialog";

function ActionIconButtons() {
  const theme = useTheme();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [supportRequestOpen, setSupportRequestOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {t} = useTranslation("translation");

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 1200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };

  const handleOpenSupportRequest = (event) => {
    event.preventDefault();

    setSupportRequestOpen(true);
  };

  const handleCloseDialog = () => {
    setSupportRequestOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: theme.spacing(16),
          right: theme.spacing(2),
          zIndex: (theme) => theme.zIndex.drawer + 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: 4,
        }}
      >
        {showScrollToTop && (
          <IconButton
            onClick={handleScrollToTop}
            size="large"
            variant="contained"
            disableRipple={true}
            title={t("scroll_to_top")}
            sx={{
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ArrowUpwardIcon sx={{fontSize: 24}} />
          </IconButton>
        )}
        <Box
          onClick={handleOpenSupportRequest}
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.5s ease",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "24px",
            overflow: "hidden",
            width: isHovered ? "auto" : "46px",
            height: "46px",
            cursor: "pointer",
            "&:hover": {
              width: "auto",
            },
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "46px",
              height: "46px",
            }}
          >
            <ContactSupportOutlinedIcon sx={{fontSize: 28, color: theme.palette.primary.contrastText}} />
          </Box>
          {isHovered && (
            <Box
              component="span"
              sx={{
                color: theme.palette.primary.contrastText,
                padding: "0 16px 0 0",
                whiteSpace: "nowrap",
              }}
            >
              <strong>{t("contact_an_expert")}</strong>
            </Box>
          )}
        </Box>
      </Box>
      <SupportRequestDialog open={supportRequestOpen} onClose={handleCloseDialog} />
    </>
  );
}

export default ActionIconButtons;
