export const physicalValueToText = (value, unit, numberOfDecimal) => {
  /**
   * @param {number|null|undefined} value
   * @param {string} unit
   * @param {number} numberOfDecimal
   */
  if (value === null || value === undefined || isNaN(value)) {
    return "-";
  }
  const formattedValue = Number(value).toFixed(numberOfDecimal).replace("-0", "0");

  return `${formattedValue} ${unit}`;
};

export const userToFullName = (user) => {
  /**
   * @param {object} user
   * user example: {
   *      first_name: 'John',
   *      last_name: 'Lennon'
   * }
   */

  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else if (user.first_name) {
    return user.first_name;
  } else if (user.last_name) {
    return user.last_name;
  } else if (user.username) {
    return user.username;
  }

  return null;
};

export const userToAvatarString = (user) => {
  /**
   * @param {object} user
   * user example: {
   *      first_name: 'John',
   *      last_name: 'Lennon'
   * }
   */

  if (user.first_name && user.last_name) {
    return `${user.first_name[0]}${user.last_name[0]}`;
  } else if (user.first_name) {
    return user.first_name[0];
  } else if (user.last_name) {
    return user.last_name[0];
  } else if (user.username) {
    return user.username[0];
  }

  return null;
};
