import merge from "deepmerge";
import {green, grey, red, yellow, orange, lime, blue, cyan, deepOrange} from "@mui/material/colors";
import {THEMES} from "../constants";
import {
  mainLogoForDarkBackground,
  inlineLogoForDarkBackground,
  inlineLogoForLightBackground,
  mainLogoForLightBackground,
} from "assets";

const vadimapGreen = {
  50: "#daf6f1",
  100: "#a3e8d9",
  200: "#5CD9C1", // Main
  300: "#00c7a6",
  400: "#00b894",
  500: "#00a982",
  600: "#009b75",
  700: "#008a65",
  800: "#007a56",
  900: "#005d3a",
};

const vadimapBlue = {
  50: "#e8eef8",
  100: "#c9d5e2",
  200: "#aab8c9",
  300: "#8a9bb1",
  400: "#73869e",
  500: "#5b728c",
  600: "#4e647b",
  700: "#3f5165",
  800: "#303e4f", // Main
  850: "#293442",
  900: "#1e2a38",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: vadimapBlue[800],
      contrastText: "#ffffff",
    },
    secondary: {
      main: vadimapGreen[800],
      contrastText: "#ffffff",
    },
    vadimap: {
      main: vadimapGreen[200],
      secondary: vadimapBlue[800],
    },
    background: {
      default: grey[200],
      paper: "#ffffff",
    },
    success: {
      main: green[600],
      background: "#edf7ed",
      contrastText: "#1E4620",
    },
    info: {
      main: blue[600],
      background: "#e5f6fd",
      contrastText: "#014361",
    },
    warning: {
      main: orange[600],
      background: "#fff4e5",
      contrastText: "#663C00",
    },
    error: {
      main: red[600],
      background: "#fdeded",
      contrastText: "#5F2120",
    },
    performance_worst: {
      main: grey[700],
      contrastText: "#ffffff",
    },
    performance_bad: {
      main: grey[500],
      contrastText: vadimapBlue[900],
    },
    performance_medium: {
      main: green.A100,
      contrastText: vadimapBlue[900],
    },
    performance_good: {
      main: green.A200,
      contrastText: vadimapBlue[900],
    },
    performance_best: {
      main: green.A700,
      contrastText: vadimapBlue[900],
    },
    very_important: {
      main: red.A700,
    },
    fairly_important: {
      main: red[600],
    },
    important: {
      main: orange[500],
    },
    slightly_important: {
      main: blue[600],
    },
    not_at_all_important: {
      main: blue.A700,
    },
    consumption: {
      main: vadimapBlue[800],
    },
    solar: {
      main: yellow.A700,
    },
    battery: {
      main: green.A700,
      great: green.A700,
      good: lime.A700,
      medium: yellow.A700,
      bad: red.A700,
      light: green.A200,
    },
    electricalVehicle: {
      main: cyan.A700,
    },
    generator: {
      main: orange.A700,
    },
    grid: {
      main: red.A700,
    },
    dropZone: {
      main: grey[50],
      borderColor: grey[400],
      color: grey[400],
    },
    buildingStatus: {
      operating: {
        main: green["A400"],
        textContrast: vadimapBlue[900],
      },
      installation: {
        main: cyan["A400"],
        textContrast: vadimapBlue[900],
      },
      quotation: {
        main: blue["A200"],
        textContrast: vadimapBlue[900],
      },
      quotation_in_progress: {
        main: blue["A200"],
        textContrast: vadimapBlue[900],
      },
      quotation_ready: {
        main: blue["A200"],
        textContrast: vadimapBlue[900],
      },
      prescription: {
        main: yellow[500],
        textContrast: vadimapBlue[900],
      },
      prescription_in_progress: {
        main: yellow[500],
        textContrast: vadimapBlue[900],
      },
      prescription_ready: {
        main: yellow[500],
        textContrast: vadimapBlue[900],
      },
      questionnaire: {
        main: orange["A200"],
        textContrast: vadimapBlue[900],
      },
      questionnaire_in_progress: {
        main: orange["A200"],
        textContrast: vadimapBlue[900],
      },
      questionnaire_pending: {
        main: orange["A200"],
        textContrast: vadimapBlue[900],
      },
      questionnaire_completed: {
        main: orange["A200"],
        textContrast: vadimapBlue[900],
      },
      archived: {
        main: grey[700],
        textContrast: grey[50],
      },
      pending: {
        main: grey[300],
        textContrast: vadimapBlue[900],
      },
      to_go: {
        main: deepOrange["A200"],
        textContrast: vadimapBlue[900],
      },
    },
    dispatchStatus: {
      very_high: {
        main: green["A700"],
      },
      high: {
        main: green["A200"],
      },
      medium: {
        main: green["A100"],
      },
      low: {
        main: grey[500],
      },
      very_low: {
        main: grey[700],
      },
      not_dispatched: {
        main: red[600],
      },
    },
  },
  header: {
    color: grey[600],
    background: grey[50],
    search: {
      color: grey[800],
    },
    indicator: {
      background: vadimapGreen[200],
    },
    logo: inlineLogoForLightBackground,
  },
  footer: {
    color: grey[600],
    background: grey[50],
    logo: inlineLogoForLightBackground,
  },
  sidebar: {
    color: grey[50],
    activeColor: vadimapBlue[900],
    background: vadimapBlue[800],
    header: {
      color: grey[50],
      background: vadimapBlue[800],
      brand: {
        color: vadimapGreen[200],
      },
    },
    footer: {
      color: grey[50],
      background: vadimapBlue[800],
      online: {
        background: green[500],
      },
    },
    logo: inlineLogoForDarkBackground,
  },
  mainLogo: mainLogoForLightBackground,
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: vadimapGreen[200],
      contrastText: vadimapBlue[900],
    },
    secondary: {
      main: vadimapBlue[50],
      contrastText: vadimapBlue[900],
    },
    background: {
      default: vadimapBlue[900],
      paper: vadimapBlue[800],
    },
    success: {
      background: "#0c130d",
      contrastText: "#CCE8CD",
    },
    info: {
      background: "#071318",
      contrastText: "#B8E7FB",
    },
    warning: {
      background: "#191207",
      contrastText: "#FFE2B7",
    },
    error: {
      background: "#160b0b",
      contrastText: "#F4C7C7",
    },
    consumption: {
      main: vadimapGreen[300],
    },
    text: {
      primary: grey[50],
      secondary: grey[300],
    },
    dropZone: {
      main: vadimapBlue[900],
      borderColor: "#ffffff",
      color: "#ffffff",
    },
  },
  header: {
    color: grey[300],
    background: vadimapBlue[850],
    search: {
      color: grey[200],
    },
    logo: inlineLogoForDarkBackground,
  },
  footer: {
    color: grey[300],
    background: vadimapBlue[850],
    logo: inlineLogoForDarkBackground,
  },
  sidebar: {
    activeColor: grey[50],
  },
  mainLogo: mainLogoForDarkBackground,
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: "light",
  },
  header: {
    color: grey[200],
    background: vadimapGreen[200],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: "#121212",
    background: "#ffffff",
    header: {
      color: "#ffffff",
      background: vadimapGreen[200],
      brand: {
        color: "#ffffff",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
});

const variants = [defaultVariant, darkVariant, lightVariant];

export default variants;
