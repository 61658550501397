import React from "react";
import styled from "styled-components";
import {manageLanguages, getCurrentLanguageInfo, languageList} from "../operations";
import {useTranslation} from "react-i18next";

import {Menu, MenuItem, IconButton as MuiIconButton} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function LanguagesDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [currentLanguage, setLanguage] = React.useState(getCurrentLanguageInfo());

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const {i18n} = useTranslation("");
  const operations = manageLanguages(i18n);
  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="large"
        sx={{fontSize: "initial"}}
        data-testid="language-selector"
      >
        {currentLanguage.key.toUpperCase()}
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        {languageList.map((lang) => (
          <MenuItem
            key={lang.key}
            onClick={() => {
              setLanguage(lang);
              operations.setLanguage(lang.key);
              closeMenu();
            }}
            data-testid={`language-option-${lang.key}`}
          >
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default LanguagesDropdown;
