import React from "react";
import styled, {createGlobalStyle} from "styled-components";
import {CssBaseline} from "@mui/material";
import {Outlet} from "react-router-dom";

const GlobalStyle = createGlobalStyle`
    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background: ${(props) => props.theme.palette.background.default};
    }
`;

const Root = styled.div`
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
`;

const Auth = ({children}) => {
  return (
    <Root>
      <CssBaseline/>
      <GlobalStyle/>
      {children}
      <Outlet/>
    </Root>
  );
};

export default Auth;
