const RoutePaths = {
    AUTH_LOGIN: "/auth/login/",
    CUSTOMER_ONBOARDING: "/user/onboarding/",
    CUSTOMER_HOME: "/user/buildings/list/", //TODO: /user/home
    CUSTOMER_PROJECT: "/user/project/",
    CUSTOMER_DASHBOARD: "/user/dashboard/",
    CUSTOMER_PRESCRIPTION: "/user/prescription/",
    CUSTOMER_DUMMY_DASHBOARD: "/user/dummy_dashboard/",
    NOT_AUTHORIZED: "/not_authorized/",
    SERVER_ERROR: "/error/",
    AUTH_RESET_PASSWORD: "/auth/reset_password/",
    AUTH_NEW_PASSWORD: "/auth/new_password/:token",
    CUSTOMER_PROFILE: "/user/profile/",
    CUSTOMER_BUILDINGS: "/user/buildings/",
    CUSTOMER_BUILDINGS_LIST: "/user/buildings/list/",
    CUSTOMER_BUILDINGS_MAP: "/user/buildings/map/",
    CUSTOMER_ADD_BUILDING: "/user/buildings/add/",
    CUSTOMER_DISPATCH: "/user/dispatch/",
    CUSTOMER_DISPATCH_LIST: "/user/dispatch/list/",
    CUSTOMER_DISPATCH_MAP: "/user/dispatch/map/",
    MAPREMIUM_SUBSCRIPTION: {
        MAP: "/mapremium_subscription/map/",
        DASHBOARD: "/mapremium_subscription/dashboard/",
    },
    ENTERPRISE_PLANNER: {
        BUILDING_UPLOAD: "/enterprise_planner/upload/",
    },
};

const ApiPaths = {
    GET_JWT: "/api/token/",
    REFRESH_JWT: "/api/token/refresh/",
    ACCOUNT_INFO: "/api/account_info/",
    OBJECTIVE_WEIGHTS: "/api/objective_weights/",
    QUESTIONNAIRE: "/api/questionnaire/",
    // QUESTIONNAIRE_WITH_ID : (questionnaire_id) =>  `/api/questionnaire/${questionnaire_id}/`,
    QUESTIONNAIRES: "/api/questionnaires/",
    REVIEWED_QUESTIONNAIRE: "/api/reviewed_questionnaire/",
    FILES: "/api/files/",
    PROJECT_OVERVIEW: "/api/project_overview/",
    PROJECT: "/api/project/",
    PROJECTS: "/projects/",
    RESET_PASSWORD: "/api/user/reset_password/",
    NEW_PASSWORD: "/api/user/reset_password/confirm/",
    VALIDATE_RESET_PASSWORD_TOKEN: "/api/user/reset_password/validate_token/",
    BUILDINGS: "/api/buildings/",
    BUILDINGS_OVERVIEW: "/api/buildings/overview/",
    BUILDINGS_ID: "/api/buildings/id/",
    BUILDINGS_COUNT_BY_STATUS: "/api/buildings/count_by_status/",
    BUILDINGS_COUNT_BY_DISPATCH_POTENTIAL: "/api/buildings/count_by_dispatch_potential/",
    BUILDING: "/api/building/",
    BUILDINGS_CLASSIFICATION_TYPES: "/api/buildings/classification_types/",
    BUILDINGS_STATUSES: "/api/buildings/statuses/",
};

const stateMachineApiPaths = {
    PROJECT: "/project/",
    ASYNC_LOCATION_INFORMATION: "/async_location_information",
    ASYNC_DISPATCH_INFORMATION: "/states/async_dispatch_information",
};

const weatherApiPaths = {
    CURRENT: "/current/",
};

const DJANGO_HOST = process.env.REACT_APP_API_ENDPOINT || "http://127.0.0.1:8000";
for (const property in ApiPaths) {
    ApiPaths[property] = DJANGO_HOST + ApiPaths[property];
}

const WEATHER_API_BASE_URL = process.env.REACT_APP_WEATHERBIT_API_ENDPOINT || "https://api.weatherbit.io/v2.0";
for (const property in weatherApiPaths) {
    weatherApiPaths[property] = WEATHER_API_BASE_URL + weatherApiPaths[property];
}

export { ApiPaths, weatherApiPaths, stateMachineApiPaths };

export default RoutePaths;
