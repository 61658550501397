import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { baseApi } from "state/slices/apiSlice";
import { weatherBaseApi } from "./slices/weatherApiSlice";

const createRootReducer = () => {
    const appReducer = combineReducers({
        [baseApi.reducerPath]: baseApi.reducer,
        [weatherBaseApi.reducerPath]: weatherBaseApi.reducer,
    });
    return (state, action) => {
        if (action.type === 'LOGOUT') {
            state = undefined;
        }  

        return appReducer(state, action);  
    }
};

export const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([baseApi.middleware, weatherBaseApi.middleware]),
});
