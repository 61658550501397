import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery, Box, ButtonBase, Link,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Close, Email, Phone} from "@mui/icons-material";
import {useState} from "react";
import PropTypes from "prop-types";
import {useEffect} from "react";

function SupportRequestDialog({open, onClose}) {
  const {t} = useTranslation();
  const theme = useTheme();

  const [innerOpen, setInnerOpen] = useState(false);

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setInnerOpen(open);
  }, [open]);

  const handleClose = (event) => {
    onClose(event);
    setInnerOpen(false);
  };

  return (
    <Dialog open={innerOpen} onClose={handleClose}>
      <DialogTitle>
        {t("support_request.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText whiteSpace="pre-line" mt={4}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Box display="flex" flexDirection="column" gap={4}>
              <Link
                color={theme.palette.text.primary}
                href="mailto:info@vadimap.com"
              >
                <Box display="flex" gap={1}><Email />info@vadimap.com</Box>
              </Link>
              <Link
                color={theme.palette.text.primary}
                href="tel:+1 833 823-4600,4"
              >
                <Box display="flex" gap={1}><Phone />+1 833 823-4600,4</Box>
              </Link>
            </Box>
            {t("support_request.text")}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={isSmDown ? {flexDirection: "column", gap: 4} : {}}>
        <Button variant="contained" autoFocus fullWidth={isSmDown} startIcon={<Close />} onClick={handleClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SupportRequestDialog;
