import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "Auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders(headers) {
        const token = localStorage.getItem("atkey");

        if (token) {
            headers.set("Authorization", `JWT ${token}`);
        }
        return headers;
    },
});

const baseQueryAuth = async (args, api, extraOptions) => {
    await getToken();
    let result = await baseQuery(args, api, extraOptions);
    return result;
};

export const baseApi = createApi({
    baseQuery: baseQueryAuth,
    endpoints: () => ({}),
    tagTypes: ["Projects", "Buildings", "Questionnaire", "Account"],
});
