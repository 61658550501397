import async from "components/Async";
import AuthGuard, {
  AuthGuardWithoutProfile,
  AuthGuardEntreprisePlannerSubscription,
  AuthGuardEnergyAssistantSubscription,
  AuthGuardDummyDashboard,
  AuthGuardProject,
} from "components/AuthGuard";
import Routes from "./Routes";
import RoutePaths from "./ROUTE_PATHS";
import {Domain, FormatListNumbered} from "@mui/icons-material";
import {Person} from "@mui/icons-material";

// const CustomerHome = async(()=>import('pages/CustomerHome'))
const CustomerBuildings = async(() => import("pages/List/Buildings"));
const CustomerBuildingsMap = async(() => import("pages/Maps/Buildings"));
const CustomerDispatchMap = async(() => import("pages/Maps/Dispatch"));
const CustomerDispatchList = async(() => import("pages/List/Dispatch"));
const AccountInfo = async(() => import("pages/AccountInfo"));
const Project = async(() => import("pages/Project"));
//const FeasibilityAnalysis = async(() => import("pages/FeasibilityAnalysis"));
const DataDisplay = async(() => import("pages/DataDisplay"));
const Prescription = async(() => import("pages/Prescription"));

// AUTH
const SignIn = async(() => import("pages/Auth/SignIn"));
const Page403 = async(() => import("pages/Auth/Page403"));
const Page404 = async(() => import("pages/Auth/Page404"));
const Page500 = async(() => import("pages/Auth/Page500"));
const ResetPassword = async(() => import("pages/Auth/ResetPassword"));
const NewPassword = async(() => import("pages/Auth/NewPassword"));
const AddBuilding = async(() => import("pages/AddBuilding"));

// ONBOARDING
const CustomerOnboarding = async(() => import("pages/CustomerOnboarding"));

// MAPREMIUM
const MapremiumSubscription = async(() => import("pages/MapremiumSubscription"));

// ENTERPRISE PLANNER
// const EnterprisePlannerBuildingUpload = async(() => import("pages/EnterprisePlanner/BuildingUpload"));

// --------------------------------
// ---------- BUILDINGS -----------
// --------------------------------

const customerBuildingsRoute = {
  id: "navigation.sidebar.buildings",
  name: "navigation.sidebar.buildings",
  path: RoutePaths.CUSTOMER_BUILDINGS,
  icon: <Domain />,
  children: [
    {
      path: RoutePaths.CUSTOMER_BUILDINGS_LIST,
      name: "navigation.sidebar.buildings_list",
      containsHome: true,
      component: CustomerBuildings,
      guard: AuthGuard,
    },
    {
      path: RoutePaths.CUSTOMER_BUILDINGS_MAP,
      name: "navigation.sidebar.buildings_map",
      component: CustomerBuildingsMap,
      guard: (AuthGuard, AuthGuardEntreprisePlannerSubscription),
    },
  ],
  component: null,
};

// --------------------------------
// ----------- DISPATCH -----------
// --------------------------------

const customerDispatchRoute = {
  id: "navigation.sidebar.dispatch",
  name: "navigation.sidebar.dispatch",
  path: RoutePaths.CUSTOMER_DISPATCH,
  icon: <FormatListNumbered />,
  children: [
    {
      path: RoutePaths.CUSTOMER_DISPATCH_LIST,
      name: "navigation.sidebar.dispatch_list",
      component: CustomerDispatchList,
      guard: (AuthGuard, AuthGuardEntreprisePlannerSubscription),
    },
    {
      path: RoutePaths.CUSTOMER_DISPATCH_MAP,
      name: "navigation.sidebar.dispatch_map",
      component: CustomerDispatchMap,
      guard: (AuthGuard, AuthGuardEntreprisePlannerSubscription),
    },
  ],
  component: null,
};

// --------------------------------
// --------- PRESCRIPTION ---------
// --------------------------------

const customerPrescription = {
  id: "Prescription",
  name: "Prescription",
  path: RoutePaths.CUSTOMER_PRESCRIPTION + ":project_id",
  component: Prescription,
  guard: (AuthGuard, AuthGuardProject),
};

// --------------------------------
// ----------- MAPREMIUM ----------
// --------------------------------

const mapremiumSubscriptionMapRoute = {
  id: "MAPremium Subscription Map",
  name: "MAPremium Subscription Map",
  path: RoutePaths.MAPREMIUM_SUBSCRIPTION.MAP,
  component: MapremiumSubscription,
  guard: AuthGuard,
  backgroundImage: "url(/static/img/mapremium/map.jpg)",
};

const mapremiumSubscriptionDashboardRoute = {
  id: "MAPremium Subscription Dashboard",
  name: "MAPremium Subscription Dashboard",
  path: RoutePaths.MAPREMIUM_SUBSCRIPTION.DASHBOARD,
  component: MapremiumSubscription,
  guard: AuthGuard,
  backgroundImage: "url(/static/img/mapremium/dashboard_load_analysis.jpg)",
};

// --------------------------------
// ----------- DASHBOARD ----------
// --------------------------------

const customerDataDisplay = {
  id: "Energy Assistant Dashboard",
  name: "Energy Assistant Dashboard",
  path: RoutePaths.CUSTOMER_DASHBOARD + ":project_id/:active_tab",
  component: DataDisplay,
  guard: (AuthGuard, AuthGuardProject, AuthGuardEnergyAssistantSubscription),
};

const customerDummyDataDisplay = {
  id: "Energy Assistant Dummy Dashboard",
  name: "Energy Assistant Dummy Dashboard",
  path: RoutePaths.CUSTOMER_DUMMY_DASHBOARD + ":project_id/:active_tab/:dummy_token",
  component: DataDisplay,
  guard: AuthGuardDummyDashboard,
};

// --------------------------------
// ------ ENTERPRISE PLANNER ------
// --------------------------------

// @todo reactive this when the csv upload is done
// const enterprisePlannerBuildingUploadRoute  = {
//     id: "Upload",
//     path: RoutePaths.ENTERPRISE_PLANNER.BUILDING_UPLOAD,
//     component: EnterprisePlannerBuildingUpload,
//     guard: (AuthGuard, AuthGuardEntreprisePlannerSubscription),
// };

// --------------------------------
// ------- CUSTOMER PROFILE -------
// --------------------------------

const customerAccountInfo = {
  id: "navigation.sidebar.profile",
  name: "navigation.sidebar.profile",
  path: RoutePaths.CUSTOMER_PROFILE,
  icon: <Person />,
  component: AccountInfo,
  guard: AuthGuardWithoutProfile, //doesnt redirect to profile page if incomplete
};

// --------------------------------
// ------------ OTHER -------------
// --------------------------------

const customerProjectRoute = {
  path: RoutePaths.CUSTOMER_PROJECT + ":questionnaire_id",
  component: Project,
  guard: AuthGuard,
};

/*
const customerFeasibilityAnalysis = {
    path: RoutePaths.CUSTOMER_FEASIBILITY_ANALYSIS,
    component: FeasibilityAnalysis,
    guard: AuthGuard,
};
*/

const addBuildingRoute = {
  id: "Add Building",
  name: "Add Building",
  path: RoutePaths.CUSTOMER_ADD_BUILDING,
  component: AddBuilding,
  guard: AuthGuard,
};

// --------------------------------
// ---------- ONBOARDING ----------
// --------------------------------

const customerOnboardingRoute = {
  id: "CustomerOnboarding",
  path: RoutePaths.CUSTOMER_ONBOARDING,
  component: CustomerOnboarding,
};

// --------------------------------
// ------- AUTHENTIFICATION -------
// --------------------------------

const page403Route = {
  id: "Page403",
  path: RoutePaths.NOT_AUTHORIZED,
  component: Page403,
};

const page404Route = {
  id: "Page404",
  path: "*",
  component: Page404,
};

const page500Route = {
  id: "Page500",
  path: RoutePaths.SERVER_ERROR,
  component: Page500,
};

const signIn = {
  id: "SignIn",
  path: RoutePaths.AUTH_LOGIN,
  component: SignIn,
};

const resetPassword = {
  id: "ResetPassword",
  path: RoutePaths.AUTH_RESET_PASSWORD,
  component: ResetPassword,
};

const newPassword = {
  id: "NewPassword",
  path: RoutePaths.AUTH_NEW_PASSWORD,
  component: NewPassword,
};

// --------------------------------
// ------------ LAYOUT ------------
// --------------------------------

export const mainLayoutRoutes = [
  customerAccountInfo,
  customerProjectRoute,
  customerDataDisplay,
  customerDummyDataDisplay,
  customerPrescription,
  // enterprisePlannerBuildingUploadRoute,
  customerBuildingsRoute.children[0],
  customerDispatchRoute.children[0],
  addBuildingRoute,
];

export const mapLayoutRoutes = [
  mapremiumSubscriptionMapRoute,
  mapremiumSubscriptionDashboardRoute,
  customerBuildingsRoute.children[1],
  customerDispatchRoute.children[1],
];

export const authLayoutRoutes = [signIn, resetPassword, newPassword, page500Route, page403Route, page404Route];
export const onboardingLayoutRoutes = [customerOnboardingRoute];
export const sidebarRoutes = [customerBuildingsRoute, customerDispatchRoute, customerAccountInfo];

export {RoutePaths};
export default Routes;
