import React, { useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "styles/perfect-scrollbar.css";
import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import {
  // Badge,
  Chip,
  // Grid,
  // Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { sidebarRoutes as routes } from "navigation/routes/index";
import BrandIcon from "./BrandIcon";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

// const BrandIcon = styled(logo)`
//   margin-right: ${(props) => props.theme.spacing(2)}px;
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 32px;
//   height: 32px;
// `;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.palette.background.default};

    span,
    svg {
      color: ${(props) => props.theme.sidebar.activeColor};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: ${(props) => props.theme.spacing(2)} 0 ${(props) => props.theme.spacing(2)}
    ${(props) => props.theme.spacing(2)};

  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) => darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.palette.background.default};

    span,
    svg {
      color: ${(props) => props.theme.sidebar.activeColor};
    }
  }
`;

const LinkText = styled(ListItemText)`
  margin-left: ${(props) => props.theme.spacing(10)};
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  background: ${(props) => props.theme.palette.vadimap.main};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.vadimap.secondary};
  }
`;

const CategoryBadge = styled(LinkBadge)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarCategory = ({ name, icon, isOpen, isCollapsable, badge, to, onClick }) => {
  return (
    <Category button component={!isCollapsable ? NavLink : 'div'} to={to} onClick={onClick}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {badge ? <CategoryBadge label={badge} /> : ""}
      {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
    </Category>
  );
};

const SidebarSubCategory = ({ name, to, badge, icon, dense }) => {
  return (
    <Link button dense={dense} component={NavLink} to={to} activeclassname="active">
      {icon}
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const withRouter = (Component) => {
  const Wrapper = (props) => {
    return <Component {...props} />;
  };
  return Wrapper;
};

function Sidebar({ classes, staticContext, ...rest }) {
  const location = useLocation();
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */

    const pathName = location.pathname;

    let _routes = {};
    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const { t, ready } = useTranslation("translation");

  return ready ? (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <BrandIcon />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={t(category.name)}
                      icon={category.icon}
                      badge={category.badge}
                      button={true}
                      onClick={() => toggle(index)}
                    />

                    <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                      {category.children.map((route, index) => (
                        <SidebarSubCategory
                          key={index}
                          name={t(route.name)}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={t(category.name)}
                    to={category.path}
                    component={NavLink}
                    icon={category.icon}
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  ) : (
    <CircularProgress />
  );
}

export default withRouter(Sidebar);
