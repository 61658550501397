import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "navigation/routes";
import { logout } from "Auth";
import { useTranslation } from "react-i18next";
import { Logout, Person } from "@mui/icons-material";
import { Menu, MenuItem, ListItemIcon, Avatar, IconButton, Divider, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAccount } from "lib/hook";
import { userToAvatarString } from "lib/parsing";

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const { account } = useAccount();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    logout();
    dispatch({ type: "LOGOUT" }); // @todo remove it when the auth is handle by Redux
    navigate(RoutePaths.AUTH_LOGIN);
    closeMenu();
  };

  const handleProfile = async () => {
    navigate(RoutePaths.CUSTOMER_PROFILE);
    closeMenu();
  };

  return (
    <>
      <IconButton onClick={toggleMenu}>
        <Avatar
          sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
        >
          {userToAvatarString(account)}
        </Avatar>
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          {t("navigation.sidebar.profile")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("auth.signout")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserDropdown;
