import {
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  AUTH_TOKEN_START_TIMESTAMP,
  REFRESH_TOKEN_START_TIMESTAMP,
  USER_PROFILE,
  USER_PROFILE_OK,
} from "./constants";
import {ApiPaths} from "navigation/routes/ROUTE_PATHS";

const JWT_EXPIRATION_MIN = 5;
const REFRESH_EXPIRATION_DAYS = 7;
const REFRESH_EXPIRATION_MIN = REFRESH_EXPIRATION_DAYS * 24 * 60;

const getRequestObject = (method, data, headers) => {
  if (method === "POST") {
    return {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(data),
    };
  } else {
    return {
      method: method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    };
  }
};

const tokenIsExpired = (startTs, expirationInMin) => {
  const timeDifferenceWithOffset = (ts1, ts2) => {
    return (ts1 - ts2) / 1000 / 60 + 1;
  };
  if (startTs === null) {
    return true;
  }
  if (timeDifferenceWithOffset(Date.now(), startTs) >= expirationInMin) {
    return true;
  } else {
    return false;
  }
};

const resetPassword = async (email) => {
  const data = {email: email};

  try {
    let response = await fetch(ApiPaths.RESET_PASSWORD, getRequestObject("POST", data, {}));
    let result = await response.json();

    if (response.ok) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        error: result,
      };
    }
  } catch {
    return {
      ok: false,
      error: "A server error as occured",
    };
  }
};

const newPassword = async (password, token) => {
  const data = {token: token, password: password};

  try {
    let response = await fetch(ApiPaths.NEW_PASSWORD, getRequestObject("POST", data, {}));
    let result = await response.json();

    if (response.ok) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        error: result.password ? result.password.join(" ") : result.detail,
      };
    }
  } catch {
    return {
      ok: false,
      error: "A server error as occured",
    };
  }
};

const validateResetPasswordToken = async (token) => {
  const data = {token: token};

  try {
    let response = await fetch(ApiPaths.VALIDATE_RESET_PASSWORD_TOKEN, getRequestObject("POST", data, {}));
    let result = await response.json();

    if (response.ok) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        error: result.detail,
      };
    }
  } catch {
    return {
      ok: false,
      error: "A server error as occured",
    };
  }
};

const logout = () => {
  localStorage.removeItem(AUTH_TOKEN_START_TIMESTAMP);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_START_TIMESTAMP);
  localStorage.clear();
};

const requiresLogIn = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const refreshTokenIsExpired = tokenIsExpired(
    localStorage.getItem(REFRESH_TOKEN_START_TIMESTAMP),
    REFRESH_EXPIRATION_MIN,
  );
  if (refreshToken === null || refreshTokenIsExpired) {
    return true;
  }
};

const getToken = async () => {
  if (requiresLogIn()) {
    logout();
    return null;
  }
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const accessTokenIsExpired = tokenIsExpired(localStorage.getItem(AUTH_TOKEN_START_TIMESTAMP), JWT_EXPIRATION_MIN);
  if (token === null || accessTokenIsExpired) {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    const data = {
      refresh: refreshToken,
    };
    let response = await fetch(ApiPaths.REFRESH_JWT, getRequestObject("POST", data, {}));
    if (response.ok) {
      let result = await response.json();
      localStorage.setItem(AUTH_TOKEN_KEY, result.access);
      localStorage.setItem(AUTH_TOKEN_START_TIMESTAMP, Date.now());
      return result.access;
    } else {
      logout();
      return null;
    }
  }
  return token;
};

const getAuthRequestObject = async (method, data, headers) => {
  // IF returns Null, it means we need to redirect user
  if (method !== "POST" && method !== "GET" && method !== "DELETE") {
    return null;
  }
  const token = await getToken();
  if (token === null) {
    return null;
  }
  const requestHeaders = {
    Authorization: `JWT ${token}`,
    ...headers,
  };
  const requestObject = getRequestObject(method, data, requestHeaders);
  return requestObject;
};

const login = async (username, password) => {
  const data = {
    username: username,
    password: password,
  };

  try {
    let response = await fetch(ApiPaths.GET_JWT, getRequestObject("POST", data, {}));
    let result = await response.json();

    if (response.ok) {
      const now = Date.now();
      localStorage.setItem(AUTH_TOKEN_KEY, result.access);
      localStorage.setItem(REFRESH_TOKEN_KEY, result.refresh);
      localStorage.setItem(AUTH_TOKEN_START_TIMESTAMP, now);
      localStorage.setItem(REFRESH_TOKEN_START_TIMESTAMP, now);
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        error: result.detail,
      };
    }
  } catch {
    return {
      ok: false,
      error: "A server error as occured",
    };
  }
};

const getAccountInfo = async () => {
  try {
    let requestObject = await getAuthRequestObject("GET", {}, {});
    let response = await fetch(ApiPaths.ACCOUNT_INFO, requestObject);
    if (response.ok) {
      let result = await response.json();
      return result;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

const haveEnterprisePlannerSubscription = async () => {
  const profile = await getAccountInfo();

  return profile?.licenses.includes("enterprise_planner");
};

export {
  login,
  logout,
  getAuthRequestObject,
  getAccountInfo,
  requiresLogIn,
  haveEnterprisePlannerSubscription,
  getToken,
  resetPassword,
  newPassword,
  validateResetPasswordToken,
};
