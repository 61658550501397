import React from "react";

import { IconButton } from "@mui/material";
import { useThemeMode } from "lib/hook";
import { DarkMode, LightMode } from "@mui/icons-material";


function ThemeModeButton() {
    const { mode, setMode } = useThemeMode();

    const toggleThemeMode = (event) => {
        if (mode !== "DARK") {
            setMode("DARK");
        } else {
            setMode("DEFAULT");
        }
    };

    return <IconButton onClick={toggleThemeMode}>{mode !== "DARK" ? <LightMode /> : <DarkMode />}</IconButton>;
}

export default ThemeModeButton;
