import React, {createContext, useContext, useState} from "react";

const SelectedPrescriptionConfiguration = createContext();

export const SelectedPrescriptionConfigurationContext = ({children}) => {
  const [selectedPrescriptionConfigurationData, setSelectedPrescriptionConfigurationData] = useState({});

  const updateSelectedPrescriptionConfiguration = (id, name) => {
    setSelectedPrescriptionConfigurationData({id, name});
  };

  return (
    <SelectedPrescriptionConfiguration.Provider
      value={{selectedPrescriptionConfigurationData, updateSelectedPrescriptionConfiguration}}
    >
      {children}
    </SelectedPrescriptionConfiguration.Provider>
  );
};

export const useSelectedPrescriptionConfigurationContext = () => useContext(SelectedPrescriptionConfiguration);
