import {alpha} from "@mui/material";

const overrides = {
  MuiCssBaseline: {
    styleOverrides: (themeParam) => `
      :root {
        --toastify-color-info: ${themeParam.palette.info.background} !important;
        --toastify-color-success: ${themeParam.palette.success.background} !important;
        --toastify-color-warning: ${themeParam.palette.warning.background} !important;
        --toastify-color-error: ${themeParam.palette.error.background} !important;
        --toastify-text-color-info: ${themeParam.palette.info.contrastText} !important;
        --toastify-text-color-success: ${themeParam.palette.success.contrastText} !important;
        --toastify-text-color-warning: ${themeParam.palette.warning.contrastText} !important;
        --toastify-text-color-error: ${themeParam.palette.error.contrastText} !important;
        --toastify-color-transparent: ${alpha(themeParam.palette.text.primary, 0.7)} !important;
      }
      .Toastify__close-button {
        color: ${themeParam.palette.text.primary} !important;
      }
      `,
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      ol: {
        alignItems: "baseline",
      },
    },
  },
};

export default overrides;
