import {Navigate, useNavigate, useLocation, useParams} from "react-router-dom";
import {requiresLogIn, haveEnterprisePlannerSubscription, haveEnergyAssistantSubscription} from "Auth";
import {RoutePaths} from "navigation/routes";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {useAccount} from "lib/hook";
import {useGetProjectQuery} from "api/projectApi";

// For routes that can only be accessed by authenticated users
function AuthGuard({children}) {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Store the initial URL when the user attempts to access a protected route with url param
    if (requiresLogIn()) {
      const currentURL = `${location.pathname}${location.search}`;
      navigate(RoutePaths.AUTH_LOGIN, {state: {from: currentURL}});
    }
  }, [navigate, location]);

  return children;
}

export default AuthGuard;

function AuthGuardDummyDashboard({children}) {
  const {dummy_token, project_id} = useParams();

  if (!dummy_token || dummy_token !== process.env.REACT_APP_DUMMY_DASHBOARD_TOKEN) {
    return <Navigate to={RoutePaths.NOT_AUTHORIZED} />;
  }
  if (!project_id || project_id !== process.env.REACT_APP_DUMMY_DASHBOARD_PROJECT_ID) {
    return <Navigate to={RoutePaths.NOT_AUTHORIZED} />;
  }

  return children;
}

function AuthGuardWithoutProfile({children}) {
  if (requiresLogIn()) {
    return <Navigate to={RoutePaths.AUTH_LOGIN} />;
  }

  return children;
}

function AuthGuardProject({children}) {
  const {project_id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;

  const {error, isError} = useGetProjectQuery({id: project_id});

  if (requiresLogIn()) {
    navigate(RoutePaths.AUTH_LOGIN, {state: {from: currentURL}});
    return null;
  }

  if (isError) {
    if (error.originalStatus === 403) {
      navigate(RoutePaths.NOT_AUTHORIZED, {state: {from: currentURL}});
    }
    return null;
  }

  return children;
}

function AuthGuardEntreprisePlannerSubscription({children}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isEnterprisePlannerSubscribed, setIsEnterprisePlannerSubscribed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        // first check that the user is authenticated
        if (requiresLogIn()) {
          const currentURL = window.location.pathname;
          navigate(RoutePaths.AUTH_LOGIN, {state: {from: currentURL}});
          return;
        }

        // next, subscription check
        const updatedIsEnterprisePlannerSubscribed = await haveEnterprisePlannerSubscription();
        setIsEnterprisePlannerSubscribed(updatedIsEnterprisePlannerSubscribed);

        // If the user is not a subscriber, redirect to
        if (!updatedIsEnterprisePlannerSubscribed) {
          const currentURL = window.location.pathname;
          navigate(RoutePaths.MAPREMIUM_SUBSCRIPTION.MAP, {state: {from: currentURL}});
        }
      } catch (error) {
      } finally {
        // Indicate that verification is complete
        setIsLoading(false);
      }
    };

    // Check the subscription as soon as the component is installed.
    checkSubscription();
  }, [navigate]);

  // Render component content if there is no immediate redirection
  if (isLoading || !isEnterprisePlannerSubscribed) {
    return <CircularProgress />;
  }

  return children;
}

function AuthGuardEnergyAssistantSubscription({children}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {project_id} = useParams();

  const {data: project, isLoading, isSuccess} = useGetProjectQuery({id: project_id});

  useEffect(() => {
    if (!isLoading) {
      if (requiresLogIn()) {
        navigate(RoutePaths.AUTH_LOGIN, {state: {from: location.pathname}});
      } else if (isSuccess && !project?.building.subscription?.license.includes("energy_assistant")) {
        navigate(RoutePaths.MAPREMIUM_SUBSCRIPTION.DASHBOARD, {state: {from: location.pathname}});
      }
    }
    // Render component content if there is no immediate redirection
  }, [navigate, location, project, isLoading, isSuccess]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return children;
}

function AuthGuardDemo({children}) {
  const {account} = useAccount();
  const isDummy = window.location.href.includes("dummy");

  if (!account) {
    return <CircularProgress />;
  }

  if (account.is_demo || isDummy) {
    return "xxx";
  }

  return children;
}

export {
  AuthGuardWithoutProfile,
  AuthGuardEntreprisePlannerSubscription,
  AuthGuardEnergyAssistantSubscription,
  AuthGuardDemo,
  AuthGuardDummyDashboard,
  AuthGuardProject,
};
