import { AccountContext, ThemeModeContext } from "App";
import { useState, useCallback, useContext } from "react";

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
}

export const useThemeMode = () => useContext(ThemeModeContext);

export const useAccount = () => useContext(AccountContext);
